import axios from "axios";

const debug = localStorage.getItem("debug");

async function get(endpoint) {
  if (debug)
    console.log(
      `%c[GET] request ${
        window.location.origin + process.env.REACT_APP_BACKEND_URL + endpoint
      }\n`,
      "color: CornflowerBlue"
    );
  const response = await axios.get(
    window.location.origin + process.env.REACT_APP_BACKEND_URL + endpoint
  );
  if (debug)
    console.log(
      `%c[GET] response ${
        window.location.origin + process.env.REACT_APP_BACKEND_URL + endpoint
      }\n`,
      "color:GreenYellow",
      response
    );
  if (response.data?.status !== "OK") {
    throw "Backend error";
  }
  return response.data?.data;
}

async function post(endpoint, data) {
  if (debug)
    console.log(
      `%c[POST] request ${
        window.location.origin + process.env.REACT_APP_BACKEND_URL + endpoint
      }\n`,
      "color: CornflowerBlue",
      data
    );
  const response = await axios.post(
    window.location.origin + process.env.REACT_APP_BACKEND_URL + endpoint,
    data
  );
  if (debug)
    console.log(
      `%c[POST] response ${
        window.location.origin + process.env.REACT_APP_BACKEND_URL + endpoint
      }\n`,
      "color:GreenYellow",
      response
    );
  if (response.data?.status !== "OK") {
    throw "Backend error";
  }
  return response.data?.data;
}

function readableFileSize(size) {
  if (size <= 0) return "0 B";
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    parseFloat((size / Math.pow(1024, i)).toFixed(2)) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
}

export { get, post, readableFileSize };
