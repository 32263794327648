import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  ContentCopy as ContentCopyIcon,
  FileDownloadOff as FileDownloadOffIcon,
  SettingsSuggest as SettingsSuggestIcon,
  ContactMail as ContactMailIcon,
} from "@mui/icons-material";

import {
  FILE_INFO_MANAGEMENT,
  NEW_DOWNLOAD_LINK,
  NEW_MANAGE_LINK,
  REMOVE_FILE,
  SET_CONTACT_EMAIL,
} from "../endpoints";
import { get, post, readableFileSize } from "../utils";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import DragNDropInput from "../components/DragNDropInput";
import { useErrorDialog } from "../contexts/ErrorDialogContext";

function uploadPercentage(actual, total) {
  return Math.round((actual / total) * 100);
}

let timer = null;

function Management() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setErrorDialog } = useErrorDialog();

  const [fileInfo, setFileInfo] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: "",
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  async function getFileInfo() {
    try {
      const data = await get(FILE_INFO_MANAGEMENT + "/" + id);
      setFileInfo(data);
      return data;
    } catch {
      setErrorDialog({
        open: true,
        title: "File not found",
        text: `File with id: ${id} does not exist. You can go to main page using return button.`,
        buttonText: "Return",
        handleButton: () => {
          navigate("/");
          setErrorDialog((prevState) => {
            return { ...prevState, open: false };
          });
        },
      });
    }
  }

  useEffect(() => {
    getFileInfo();

    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, []);

  async function uploadCallback() {
    const response = await getFileInfo();
    if (response.uploading) {
      timer = setTimeout(() => {
        uploadCallback();
      }, 3000);
    } else {
      getFileInfo();
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ConfirmDialog {...confirmDialog} />
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "50px",
          maxWidth: "1000px",
          width: "100%",
          padding: "30px 70px",
        }}
      >
        <Typography
          variant="h3"
          style={{ maxWidth: "100%", wordWrap: "break-word" }}
        >
          {fileInfo?.file_name}
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Download link
        </Typography>
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Link href={window.location.origin + "/download/" + fileInfo?.uuid}>
            {window.location.origin + "/download/" + fileInfo?.uuid}
          </Link>
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={() =>
              navigator.clipboard.writeText(
                window.location.origin + "/download/" + fileInfo?.uuid
              )
            }
          >
            Copy link
          </Button>
        </div>

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Management link
        </Typography>
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Link href={window.location.origin + "/manage/" + id}>
            {window.location.origin + "/manage/" + id}
          </Link>
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={() =>
              navigator.clipboard.writeText(
                window.location.origin + "/manage/" + id
              )
            }
          >
            Copy link
          </Button>
        </div>

        {fileInfo?.file_name !== null ? (
          fileInfo?.uploading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
                marginTop: "60px",
              }}
            >
              <Typography>Uploading...</Typography>
              <LinearProgress
                style={{
                  width: "350px",
                }}
                variant="determinate"
                value={uploadPercentage(
                  fileInfo.upload_progress,
                  parseInt(fileInfo.file_size)
                )}
              />
              <Typography>{`${uploadPercentage(
                fileInfo.upload_progress,
                parseInt(fileInfo.file_size)
              )}%`}</Typography>
            </div>
          ) : null
        ) : (
          <DragNDropInput
            style={{ marginTop: "60px" }}
            uploadCallback={() => uploadCallback()}
          />
        )}

        <TableContainer style={{ marginTop: "60px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>File info</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Size</TableCell>
                <TableCell>{readableFileSize(fileInfo?.file_size)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Downloads</TableCell>
                <TableCell>{fileInfo?.download_count}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Expiration</TableCell>
                <TableCell>
                  {new Date(fileInfo?.expiration_date).toUTCString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "80px",
            gap: "20px",
          }}
        >
          <TextField
            label="Contact email"
            onChange={(e) =>
              setFileInfo((prevState) => {
                return { ...prevState, contact_email: e.target.value };
              })
            }
            value={
              typeof fileInfo?.contact_email === "undefined" ||
              fileInfo.contact_email === null
                ? ""
                : fileInfo?.contact_email
            }
          />

          <Button
            startIcon={<ContactMailIcon />}
            variant="outlined"
            disabled={fileInfo?.contact_email === null}
            onClick={() => {
              setConfirmDialog({
                open: true,
                title: "Set new contact email?",
                text: "Current email will be replaced!",
                handleClose: () => {
                  setConfirmDialog((prevState) => ({
                    ...prevState,
                    open: false,
                  }));
                },
                handleConfirm: () => {
                  (async () => {
                    try {
                      await post(SET_CONTACT_EMAIL + "/" + id, {
                        email: fileInfo.contact_email,
                      });
                      setConfirmDialog((prevState) => ({
                        ...prevState,
                        open: false,
                      }));
                    } catch {
                      setErrorDialog({
                        open: true,
                        title: "Management error",
                        text: `Setting new contact mail failed. You can refresh page using return button.`,
                        buttonText: "Return",
                        handleButton: () => {
                          location.reload();
                        },
                      });
                    }
                  })();
                },
              });
            }}
          >
            Set email
          </Button>
        </div>

        <div style={{ display: "flex", gap: "15px", marginTop: "30px" }}>
          <Button
            disabled={!fileInfo}
            variant="outlined"
            color="warning"
            startIcon={<FileDownloadOffIcon />}
            onClick={() => {
              setConfirmDialog({
                open: true,
                title: "Generate new download link?",
                text: "Current link will no longer be available!",
                handleClose: () => {
                  setConfirmDialog((prevState) => ({
                    ...prevState,
                    open: false,
                  }));
                },
                handleConfirm: () => {
                  (async () => {
                    try {
                      await get(NEW_DOWNLOAD_LINK + "/" + id);
                      getFileInfo();
                      setConfirmDialog((prevState) => ({
                        ...prevState,
                        open: false,
                      }));
                    } catch {
                      setErrorDialog({
                        open: true,
                        title: "Management error",
                        text: `Getting new download link failed. You can refresh page using return button.`,
                        buttonText: "Return",
                        handleButton: () => {
                          location.reload();
                        },
                      });
                    }
                  })();
                },
              });
            }}
          >
            New download link
          </Button>

          <Button
            disabled={!fileInfo}
            variant="outlined"
            color="warning"
            startIcon={<SettingsSuggestIcon />}
            onClick={() => {
              setConfirmDialog({
                open: true,
                title: "Generate new management link?",
                text: "Current link will no longer be available!",
                handleClose: () => {
                  setConfirmDialog((prevState) => ({
                    ...prevState,
                    open: false,
                  }));
                },
                handleConfirm: () => {
                  (async () => {
                    try {
                      const data = await get(NEW_MANAGE_LINK + "/" + id);
                      navigate("../manage/" + data.uuid);
                      setConfirmDialog((prevState) => ({
                        ...prevState,
                        open: false,
                      }));
                    } catch {
                      setErrorDialog({
                        open: true,
                        title: "Management error",
                        text: `Getting new management link failed. You can refresh page using return button.`,
                        buttonText: "Return",
                        handleButton: () => {
                          location.reload();
                        },
                      });
                    }
                  })();
                },
              });
            }}
          >
            New management link
          </Button>
          <Button
            disabled={!fileInfo}
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => {
              setConfirmDialog({
                open: true,
                title: "Remove the file?",
                text: "File will be removed. This action is irreversible!",
                handleClose: () => {
                  setConfirmDialog((prevState) => ({
                    ...prevState,
                    open: false,
                  }));
                },
                handleConfirm: () => {
                  (async () => {
                    try {
                      await get(REMOVE_FILE + "/" + id);
                      navigate("/");
                    } catch {
                      setErrorDialog({
                        open: true,
                        title: "Management error",
                        text: `Removing file failed. You can refresh page using return button.`,
                        buttonText: "Return",
                        handleButton: () => {
                          location.reload();
                        },
                      });
                    }
                  })();
                },
              });
            }}
          >
            Remove file
          </Button>
        </div>
      </Paper>
    </div>
  );
}

export default Management;
