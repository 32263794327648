import { useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";

import {
  createTheme,
  CssBaseline,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";

import Main from "./pages/Main";
import Management from "./pages/Management";
import Download from "./pages/Download";
import { ErrorDialogProvider } from "./contexts/ErrorDialogContext";

function defaultTheme() {
  const storageTheme = localStorage.getItem("darkMode");
  if (storageTheme) {
    return storageTheme === "true";
  }
  return window?.matchMedia("(prefers-color-scheme: dark)").matches;
}

function App() {
  const [darkMode, setDarkMode] = useState(defaultTheme());

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <ErrorDialogProvider>
        <CssBaseline />
        <IconButton
          onClick={() =>
            setDarkMode((prevState) => {
              localStorage.setItem("darkMode", String(!prevState));
              return !prevState;
            })
          }
          size="large"
          style={{ position: "absolute", top: "5px", left: "5px" }}
        >
          {darkMode ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="manage/:id" element={<Management />} />
          <Route path="download/:id" element={<Download />} />
        </Routes>
      </ErrorDialogProvider>
    </ThemeProvider>
  );
}

export default App;
