import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CloudDownload as CloudDownloadIcon } from "@mui/icons-material";

import { DOWNLOAD_FILE, FILE_INFO_USER } from "../endpoints";
import { get, readableFileSize } from "../utils";
import { useErrorDialog } from "../contexts/ErrorDialogContext";

function uploadPercentage(actual, total) {
  return Math.round((actual / total) * 100);
}

function Download() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { setErrorDialog } = useErrorDialog();

  const [fileInfo, setFileInfo] = useState(null);

  async function getFileInfo() {
    try {
      const data = await get(FILE_INFO_USER + "/" + id);
      setFileInfo(data);
      return data;
    } catch {
      setErrorDialog({
        open: true,
        title: "File not found",
        text: `File with id: ${id} does not exist. You can go to main page using return button.`,
        buttonText: "Return",
        handleButton: () => {
          navigate("/");
          setErrorDialog((prevState) => {
            return { ...prevState, open: false };
          });
        },
      });
      return null;
    }
  }

  useEffect(() => {
    let timer = null;
    async function refresh() {
      const response = await getFileInfo();
      if (response.file_name === null || response.uploading) {
        timer = setTimeout(() => {
          refresh();
        }, 3000);
      } else {
        getFileInfo();
      }
    }
    refresh();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "50px",
          maxWidth: "1000px",
          width: "100%",
          padding: "30px 70px",
        }}
      >
        <Typography
          variant="h3"
          style={{ maxWidth: "100%", wordWrap: "break-word" }}
        >
          {fileInfo?.file_name}
        </Typography>

        {fileInfo?.file_name !== null ? (
          fileInfo?.uploading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
                marginTop: "60px",
              }}
            >
              <Typography>Uploading...</Typography>
              <LinearProgress
                style={{
                  width: "350px",
                }}
                variant="determinate"
                value={uploadPercentage(
                  fileInfo.upload_progress,
                  parseInt(fileInfo.file_size)
                )}
              />
              <Typography>{`${uploadPercentage(
                fileInfo.upload_progress,
                parseInt(fileInfo.file_size)
              )}%`}</Typography>
            </div>
          ) : (
            <Button
              style={{ marginTop: "60px", minWidth: "400px" }}
              size="large"
              variant="contained"
              component="label"
              startIcon={<CloudDownloadIcon />}
              onClick={() => {
                (async () => {
                  if ((await getFileInfo()) !== null) {
                    window.open(
                      window.location.origin +
                        process.env.REACT_APP_BACKEND_URL +
                        DOWNLOAD_FILE +
                        "/" +
                        id,
                      "_self"
                    );
                  }
                })();
              }}
            >
              Download
            </Button>
          )
        ) : (
          <Typography>File upload not started yet</Typography>
        )}

        <TableContainer style={{ marginTop: "60px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>File info</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Size</TableCell>
                <TableCell>{readableFileSize(fileInfo?.file_size)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Expiration</TableCell>
                <TableCell>
                  {new Date(fileInfo?.expiration_date).toUTCString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {typeof fileInfo?.contact_email === "undefined" ||
        fileInfo.contact_email === null ? null : (
          <Link
            href={"mailto:" + fileInfo.contact_email}
            style={{ marginTop: "25px" }}
          >
            Send feedback
          </Link>
        )}
      </Paper>
    </div>
  );
}

export default Download;
