import { useDropzone } from "react-dropzone";
import { useTheme } from "@mui/system";
import { Button, Typography } from "@mui/material";

import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

import { post } from "../utils";
import { UPLOAD_FILE } from "../endpoints";
import { useParams } from "react-router-dom";
import { useErrorDialog } from "../contexts/ErrorDialogContext";

function DragNDropInput({ style, uploadCallback = () => {} }) {
  const { id } = useParams();
  const { setErrorDialog } = useErrorDialog();

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
  });
  const file = acceptedFiles[0];
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        justifyContent: "center",
        ...style,
      }}
    >
      <div
        {...getRootProps()}
        style={{
          display: "flex",
          justifyContent: "center",
          minWidth: "400px",
          padding: "25px 10px",
          background: theme.palette.mode === "light" ? "#F9F9F9" : "#2b2b2b",
          border: isDragAccept
            ? "2px dashed GreenYellow"
            : isDragReject
            ? "2px dashed OrangeRed"
            : "2px dashed #B9B9B9",
          borderRadius: "10px",
        }}
      >
        <input {...getInputProps()} />
        {file ? (
          <Typography style={{ fontWeight: "bold" }}>{file.name}</Typography>
        ) : (
          <Typography>
            Drag and drop a file here, or click to select a file
          </Typography>
        )}
      </div>
      <Button
        size="large"
        variant="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
        disabled={!file}
        onClick={() => {
          let formData = new FormData();
          formData.append("file", file);
          try {
            post(UPLOAD_FILE + "/" + id, formData);
            uploadCallback();
          } catch {
            setErrorDialog({
              open: true,
              title: "Upload error",
              text: `File upload failed. You can refresh page using return button.`,
              buttonText: "Return",
              handleButton: () => {
                location.reload();
              },
            });
          }
        }}
      >
        Upload
      </Button>
    </div>
  );
}

export default DragNDropInput;
