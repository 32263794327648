import { createContext, useContext, useState } from "react";
import ErrorDialog from "../dialogs/ErrorDialog";

const ErrorDialogContext = createContext(null);

function ErrorDialogProvider({ children }) {
  const [errorDialog, setErrorDialog] = useState({
    open: false,
    handleButton: () => {},
    buttonText: "",
    title: "",
    text: "",
  });

  return (
    <ErrorDialogContext.Provider value={{ setErrorDialog }}>
      <ErrorDialog {...errorDialog} />
      {children}
    </ErrorDialogContext.Provider>
  );
}

const useErrorDialog = () => useContext(ErrorDialogContext);

export { ErrorDialogProvider, useErrorDialog };
