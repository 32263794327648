const CREATE_FILE = "create_file";
const UPLOAD_FILE = "upload_file";
const REMOVE_FILE = "remove_file";
const DOWNLOAD_FILE = "download_file";

const FILE_INFO_MANAGEMENT = "file_info_management";
const FILE_INFO_USER = "file_info_user";

const SET_CONTACT_EMAIL = "set_contact_email";
const NEW_DOWNLOAD_LINK = "new_download_link";
const NEW_MANAGE_LINK = "new_manage_link";

export {
  CREATE_FILE,
  UPLOAD_FILE,
  REMOVE_FILE,
  DOWNLOAD_FILE,
  FILE_INFO_MANAGEMENT,
  FILE_INFO_USER,
  SET_CONTACT_EMAIL,
  NEW_DOWNLOAD_LINK,
  NEW_MANAGE_LINK,
};
