import { useNavigate } from "react-router-dom";

import { Button, useTheme } from "@mui/material";

import { NoteAdd } from "@mui/icons-material";

import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as LogoDark } from "../assets/logoDark.svg";

import { get } from "../utils";
import { CREATE_FILE } from "../endpoints";
import { useErrorDialog } from "../contexts/ErrorDialogContext";

function Main() {
  const navigate = useNavigate();
  const { setErrorDialog } = useErrorDialog();

  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "50px",
      }}
    >
      {theme.palette.mode === "dark" ? (
        <LogoDark style={{ width: "400px" }} />
      ) : (
        <Logo style={{ width: "400px" }} />
      )}
      <Button
        variant="outlined"
        startIcon={<NoteAdd />}
        onClick={() => {
          (async () => {
            try {
              const data = await get(CREATE_FILE);
              navigate("manage/" + data.uuid);
            } catch {
              setErrorDialog({
                open: true,
                title: "Create error",
                text: `File creation failed. You can refresh page using return button.`,
                buttonText: "Return",
                handleButton: () => {
                  location.reload();
                },
              });
            }
          })();
        }}
      >
        Create a file
      </Button>
    </div>
  );
}

export default Main;
